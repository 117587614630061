import React, { useState } from 'react';
import Editor from '../../components/editor/editor.component';
import Inbox from '../../components/inbox/inbox.component';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Container from 'react-bootstrap/Container';

import styles from './upload.module.css';
import Screen from '../../components/screen/screen.component';

const UploadScreen = () => {
    const [droppedId, setDroppedId] = useState();

    return (
        <Screen>
            <DndProvider backend={HTML5Backend}>
                <span style={{ display: 'none' }}>{droppedId}</span>
                <Container>
                    <h3>Dokumente hinzufügen</h3>
                    <Inbox droppedId={droppedId} />
                    <Editor dropped={setDroppedId} />
                </Container>
            </DndProvider>
        </Screen>
    )
}

export default UploadScreen;

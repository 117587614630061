import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import styles from './section-editor.module.css';
import { Server } from '../server/server.component';
import { Button, Modal } from 'react-bootstrap';
import StringInput from '../string-input/string-input.component';

const SectionEditor = ({ folderId, section, selected, onSelected, onChange }) => {
    const { folders } = useContext(Server);
    const [opened, setOpened] = useState(false);
    const [openedEdit, setOpenedEdit] = useState(false);
    const [editData, setEditData] = useState({});

    const edit = () => {
        setEditData({ ...section });
        setOpenedEdit(true);
    }

    const save = () => {
        folders.sections.update(folderId, editData.id, {number: editData.number, name: editData.name}, () => {
            onChange();
            setOpenedEdit(false);
        }, console.error);
    }

    const doDelete = () => {

        setOpenedEdit(false);
    }

    return <>
        <div className={`${styles.sectionEditor} ${selected ? styles.selected : ''}`}>
            <span className={styles.label} onClick={() => onSelected(section)}>{section.number} {section.name}</span>
            <span>
                <span className={styles.button} onClick={edit}><FontAwesomeIcon icon={faPencilAlt} /></span>
                <span className={styles.button} onClick={() => setOpened(true)}><FontAwesomeIcon icon={faTrashAlt} /></span>
            </span>
        </div>
        <Modal show={openedEdit} onHide={() => setOpenedEdit(false)}
            dialogClassName={styles.myModal}>
            <Modal.Header className={styles.myModalHeader}>
                <Modal.Title className={styles.myModalTitle}>{section.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StringInput label="Nummer" value={editData.number} onChange={v => setEditData({ ...editData, number: v })} />
                <StringInput label="Name" value={editData.name} onChange={v => setEditData({ ...editData, name: v })} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpenedEdit(false)}>Abbrechen</Button>
                <Button onClick={save}>Speichern</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={opened} onHide={() => setOpened(false)}
            dialogClassName={styles.myModal}>
            <Modal.Header className={styles.myModalHeader}>
                <Modal.Title className={styles.myModalTitle}>{section.name} wirklich löschen?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Ein Abschnitt kann nur gelöscht werden, wenn keine Dokumente mehr darinnen enthalten sind.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpened(false)}>Abbrechen</Button>
                <Button onClick={doDelete}>Löschen</Button>
            </Modal.Footer>
        </Modal>
    </>;
}

export default SectionEditor

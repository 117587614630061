import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyCBFDk6FWtzpH38yc9tfILwsCpmaaN5pLM",
    authDomain: "documents-f59a1.firebaseapp.com",
    projectId: "documents-f59a1",
    storageBucket: "documents-f59a1.appspot.com",
    messagingSenderId: "253868452733",
    appId: "1:253868452733:web:d10d7affb69a2594617a14"
  };

  firebase.initializeApp(config);

  export const auth = firebase.auth();
  export const firestore = firebase.firestore();

  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account"});
  export const signInWithGoogle = () => auth.signInWithPopup(provider);

  export default firebase;

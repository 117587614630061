/** @format */

import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Modal from 'react-bootstrap/Modal'
import { useDrag } from 'react-dnd'
import { baseURL, Server } from '../server/server.component'

import styles from './upload-preview.module.css'

const UploadPreview = ({ file, onDelete }) => {
  const { inbox } = useContext(Server)
  const [opened, setOpened] = useState(false)
  const [openedReconvert, setOpenedReconvert] = useState(false)
  const [openedDelete, setOpenedDelete] = useState(false)
  const [{ opacity }, dragRef] = useDrag({
    item: file,
    type: 'InboxItem',
    canDrag: () => !file.pages.find(page => page.status !== 'CONVERTED'),
    collect: monitor => ({ opacity: monitor.isDragging() ? 0.5 : 1 }),
  })

  const callAction = evt => {
    console.log(evt)
    if (evt === 'delete') {
      setOpenedDelete(true)
    }
    if (evt === 'reconvert') {
      setOpenedReconvert(true)
    }
  }

  const doDelete = () => {
    inbox.delete(
      file.id,
      () => {
        setOpenedDelete(false)
        onDelete()
      },
      () => {}
    )
  }

  const doConvert = () => {
    setOpenedDelete(false)
  }

  return (
    <>
      <div role='Handle' ref={dragRef} style={{ marginRight: 10, opacity, position: 'relative' }}>
        <DropdownButton onSelect={callAction} title={file.name + ' (' + file.pages.length + ')'} variant={file.pages[0].status === 'CONVERTED' ? 'info' : file.pages[0].status === 'CONVERTING' ? 'warning' : 'danger'} size='sm'>
          <Dropdown.Item eventKey='delete'>Löschen</Dropdown.Item>
          <Dropdown.Item eventKey='reconvert'>Neu Konvertieren</Dropdown.Item>
        </DropdownButton>
        <img onClick={() => setOpened(true)} alt={file.name} src={`${baseURL}/inbox/${file.id}?token=${file.token}`} style={{ height: 350 }} />
      </div>
      <Modal show={opened} onHide={() => setOpened(false)} dialogClassName={styles.myModal}>
        <Modal.Header className={styles.myModalHeader}>
          <Modal.Title className={styles.myModalTitle}>{file.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img alt={file.name} src={`${baseURL}/inbox/${file.id}?token=${file.token}`} style={{ height: '80vh' }} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={openedDelete} onHide={() => setOpenedDelete(false)} dialogClassName={styles.myModal}>
        <Modal.Header className={styles.myModalHeader}>
          <Modal.Title className={styles.myModalTitle}>Löschen</Modal.Title>
        </Modal.Header>
        <Modal.Body>Soll der Scan wirklich gelöscht werden?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenedDelete(false)} variant='secondary'>
            Abbrechen
          </Button>
          <Button onClick={() => doDelete()}>Löschen</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={openedReconvert} onHide={() => setOpenedReconvert(false)} dialogClassName={styles.myModal}>
        <Modal.Header className={styles.myModalHeader}>
          <Modal.Title className={styles.myModalTitle}>Erneut konvertieren</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenedReconvert(false)} variant='secondary'>
            Abbrechen
          </Button>
          <Button onClick={() => doConvert()}>Konvertieren</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UploadPreview

/** @format */

import React, { useContext, useEffect, useState } from 'react'
import DragDrop from '../drag-drop/drag-drop.component'
import { Server } from '../server/server.component'
import UploadPreview from '../upload-preview/upload-preview.component'
import { auth } from '../../firebase/firebase.utils'

import styles from './inbox.module.css'
import { Button } from 'react-bootstrap'

const Inbox = ({ droppedId }) => {
  const [files, setFiles] = useState([])
  const { inbox } = useContext(Server)

  useEffect(() => {
    console.log('inbox effect', droppedId)
    query()
  }, [inbox, droppedId])

  const query = () => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken().then(token => {
        inbox.query(null, f => {
          f.forEach(x => {
            x.token = token
          })
          setFiles(f)
        })
      })
    }
  }

  const handleDrop = newFiles => {
    const data = new FormData()
    for (var i = 0; i < newFiles.length; i++) {
      console.log(newFiles[i])
      if (newFiles[i].name) {
        data.append('files', newFiles[i])
        data.append('lastModified', newFiles[i].lastModified)
      }
    }
    inbox.upload(data, query, error => {
      console.log(error)
    })
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Inbox</span>
        <Button onClick={query}>Aktualisieren</Button>
      </div>
      <DragDrop onDrop={handleDrop}>
        <div className={styles.dropZone}>
          {files.map((file, i) => (
            <UploadPreview key={i} file={file} onDelete={query} />
          ))}
        </div>
      </DragDrop>
    </div>
  )
}

export default Inbox

/** @format */

import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import PageHeader from './components/page-header/page-header.component'

import ServerProvider from './components/server/server.component'
import SearchResultProvider from './components/search-result/search-result.component'
import Notifications from 'react-notify-toast'
import WaitDialog from './components/wait-dialog/wait-dialog.component'

import SignInScreen from './screens/signin/signin.screen'
import HomeScreen from './screens/home/home.screen'
import UploadScreen from './screens/upload/upload.screen'
import DetailsScreen from './screens/details/details.screen'
import SettingsScreen from './screens/settings/settings.screen'
import UsersScreen from './screens/users/users.screen'
import BrowserScreen from './screens/browser/browser.screen'
import EditScreen from './screens/edit/edit.screen'

import './App.css'

const App = () => {
  return (
    <div className='App'>
      <ServerProvider>
        <SearchResultProvider>
          <Router>
            <Notifications options={{ zIndex: 10000, top: '50px' }} />
            <PageHeader />
            <Switch>
              <Route exact path='/' component={HomeScreen} />
              <Route exact path='/signin' component={SignInScreen} />
              <Route exact path='/upload' component={UploadScreen} />
              <Route exact path='/documents/:id' component={DetailsScreen} />
              <Route exact path='/documents/:id/edit' component={EditScreen} />
              <Route exact path='/browser' component={BrowserScreen} />
              <Route exact path='/settings' component={SettingsScreen} />
              <Route exact path='/users' component={UsersScreen} />
            </Switch>
          </Router>
        </SearchResultProvider>
      </ServerProvider>
      <WaitDialog />
    </div>
  )
}

export default App

import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ButtonBar from '../../components/button-bar/button-bar.component';
import DocumentViewer from '../../components/document-viewer/document-viewer.component';
import { Server } from '../../components/server/server.component';
import Tag from '../../components/tag/tag.component';
import { useHistory } from "react-router-dom";
import { auth } from '../../firebase/firebase.utils';
import { SearchResult } from '../../components/search-result/search-result.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faAngleLeft, faAngleRight, faFileDownload } from '@fortawesome/free-solid-svg-icons'

import styles from './details.module.css';

const DetailsScreen = ({ match }) => {
    const { documentList, selectedIndex, setSelectedIndex } = useContext(SearchResult);
    const { docs, folders, tags } = useContext(Server);
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [folderItems, setFolderItems] = useState([]);
    const [tagItems, setTagItems] = useState([]);
    const history = useHistory();

    useEffect(() => {
        folders.load(setFolderItems, setError);
    }, [folders]);

    useEffect(() => {
        tags.load(setTagItems, setError);
    }, [tags]);

    useEffect(() => {
        docs.get(match.params.id, d => {
            auth.currentUser.getIdToken().then(token => {
                d.token = token;
                setData(d);
            });
        }, setError);
    }, [match.params.id, docs])

    const decodeFolder = (folderId) => {
        var folder = folderItems.find(f => f.id === folderId);
        if (!folder) {
            return "99 Unbekannt";
        }
        return folder.number + " " + folder.name;
    }

    const decodeSection = (folderId, sectionId) => {
        var folder = folderItems.find(f => f.id === folderId);
        if (!folder) {
            return "99 Unbekannt";
        }
        var section = folder.sections.find(s => s.id === sectionId);
        if (!section) {
            return "99 Unbekannt";
        }
        return section.number + " " + section.name;
    }

    const decodeTag = (tagId) => {
        var tag = tagItems.find(t => t.id === tagId);
        if (!tag) {
            tag = "Unbekannt";
        }
        return tag.name;
    }

    const editDocument = () => {
        history.push(`/documents/${data.id}/edit`)
    }

    const selectDocument = diff => {
        setSelectedIndex(selectedIndex + diff);
        history.replace(`/documents/${documentList[selectedIndex + diff].id}`)
    }

    const download = () => {
        docs.download(data.id, data.title, console.error);
    }

    if (!data) {
        return null;
    }
    return (
        <Container className={styles.details}>
            <ButtonBar>
                <Button onClick={editDocument}><FontAwesomeIcon icon={faPencilAlt} /></Button>
                <Button disabled={selectedIndex === 0} onClick={() => selectDocument(-1)}><FontAwesomeIcon icon={faAngleLeft} /></Button>
                <Button disabled={selectedIndex === documentList.length - 1} onClick={() => selectDocument(+1)}><FontAwesomeIcon icon={faAngleRight} /></Button>
                <Button onClick={download}><FontAwesomeIcon icon={faFileDownload} /></Button>
            </ButtonBar>
            <div className={styles.value}><span className={styles.label}>Ausstellungsdatum:</span> <span>{data.issueDate}</span></div>
            <div className={styles.value}><span className={styles.label}>Titel:</span> <span>{data.title}</span></div>
            <div className={styles.value}><span className={styles.label}>Ordner:</span> <span>{decodeFolder(data.folder)}</span></div>
            <div className={styles.value}><span className={styles.label}>Abschnitt:</span> <span>{decodeSection(data.folder, data.section)}</span></div>
            <div className={styles.value}><span>{data.tags && data.tags.map(t => <Tag key={t}>{decodeTag(t)}</Tag>)}</span></div>
            <DocumentViewer doc={data} />
        </Container>
    )
}

export default DetailsScreen;

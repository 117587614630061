import React, { useContext, useState } from 'react';
import { Server } from '../server/server.component';
import styles from './user.module.css';

const User = ({ user }) => {
    const role = user.roles ? user.roles[0] : null;
    const [role_, setRole_] = useState(role);
    const [error, setError] = useState();
    const { users } = useContext(Server);

    const updateRole = role => {
        setRole_(role);
        users.setRoles(user.id, [role], data => {}, error => setError);
    }

    return (
        <div className={styles.user}>
            <div className={styles.id}>{user.id}</div>
            <div>{user.displayName}</div>
            <div className={styles.email}>{user.email}</div>
            <div>
                <select value={role_} onChange={({ target }) => updateRole(target.value)}>
                    <option>Keine</option>
                    <option value="admin">Admin</option>
                    <option value="user">Benutzer</option>
                    <option value="guest">Gast</option>
                </select>
            </div>
        </div>
    )
}

export default User;

/** @format */

import React, { createContext } from 'react'
import { auth } from '../../firebase/firebase.utils'
import { trackPromise } from 'react-promise-tracker'
import { notify } from 'react-notify-toast'

export const Server = createContext()

export const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://documents.beckers.cf/api'

var lastToken = null

function executeFetch(url, requestOptions, callback, errorCallback) {
  trackPromise(
    fetch(url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (error.toString().includes('NetworkError') || error.message.includes('Failed to fetch')) {
          notify.show('Der Server ist nicht erreichbar. Probiere es später nochmal.', 'error', 10000)
        } else if (errorCallback) {
          errorCallback(error)
        }
      })
  )
}

function executeGet(url, query, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    executeFetch(_url, requestOptions, callback, errorCallback)
  })
}

function executeQuery(type, query, callback, errorCallback) {
  if (query) executeGet('/' + type, { q: query }, callback, errorCallback)
  else executeGet('/' + type, null, callback, errorCallback)
}

function executePost(url, query, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    console.log('executePost ' + url, body)
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    }
    if (body) {
      myHeaders.append('Content-Type', 'application/json')
      requestOptions.body = JSON.stringify(body)
    }
    console.log('executePost ' + url, requestOptions.body)
    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    executeFetch(_url, requestOptions, callback, errorCallback)
  })
}

function executePostForm(url, form, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: form,
    }

    fetch(baseURL + url, requestOptions)
      .then(response => {
        if (response.status === 200) {
          response.json().then(result => callback(result))
          return
        }
        if (response.status === 204) {
          callback({})
          return
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          try {
            response
              .json()
              .then(error => errorCallback(error))
              .catch(error => errorCallback(error))
            return
          } catch (e) {
            console.log('+++++++++++++++++++++++++')
          }
        }
        response
          .text()
          .then(text => errorCallback({ message: text }))
          .catch(error => errorCallback(error))
      })
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executePut(url, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    }
    executeFetch(baseURL + url, requestOptions, callback, errorCallback)
  })
}

function executeDelete(url, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }

    executeFetch(baseURL + url, requestOptions, callback, errorCallback)
  })
}

function executeGetBlob(url, errorCallback, downloadName = 'file.pdf') {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    trackPromise(
      fetch(baseURL + url, requestOptions)
        .then(response => {
          console.log('received', response)
          if (response.status === 200) {
            console.log('status 200')
            return response.blob()
          }
          if (response.status === 204) {
            console.log('status 204')
            return {}
          }
          if (response.headers.get('Content-Type') === 'application/json') {
            console.log('error with json')
            return response.json().then(error => Promise.reject(error))
          }
          console.log('error without json')
          return response.text().then(text => Promise.reject(text))
        })
        .then(blob => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = downloadName
          a.click()
        })
        .catch(error => {
          if (errorCallback) errorCallback(error)
        })
    )
  })
}

const ServerProvider = ({ children }) => {
  return (
    <Server.Provider
      value={{
        docs: {
          query: (query, callback, errorCallback) => executeQuery('documents', query, callback, errorCallback),
          select: (folderId, section, callback, errorCallback) => executeGet(`/documents/${folderId}/sections/${section}`, null, callback, errorCallback),
          get: (docId, callback, errorCallback) => executeGet(`/documents/${docId}`, null, callback, errorCallback),
          fixPageCount: (docId, callback, errorCallback) => executeGet(`/documents/${docId}/pages/fix`, null, callback, errorCallback),
          ocr: (docId, callback, errorCallback) => executeGet(`/documents/${docId}/pages/ocr`, null, callback, errorCallback),
          download: (docId, name, errorCallback) => executeGetBlob(`/documents/${docId}/content`, errorCallback, name),
          update: (id, body, callback, errorCallback) => executePost(`/documents/${id}`, null, body, callback, errorCallback),
        },
        editor: {
          query: (query, callback, errorCallback) => executeQuery('editor', query, callback, errorCallback),
          select: (fileId, callback, errorCallback) => executeGet(`/editor/${fileId}/select`, null, callback, errorCallback),
          merge: (body, callback, errorCallback) => executePost('/editor', null, body, callback, errorCallback),
        },
        folders: {
          load: (callback, errorCallback) => executeQuery('folders', null, callback, errorCallback),
          create: (name, callback, errorCallback) => executePost('/folders', { name }, null, callback, errorCallback),
          update: (id, body, callback, errorCallback) => executePut(`/folders/${id}`, body, callback, errorCallback),
          delete: (id, callback, errorCallback) => executeDelete(`/folders/${id}`, callback, errorCallback),
          sections: {
            create: (folderId, name, callback, errorCallback) => executePost(`/folders/${folderId}`, { name }, null, callback, errorCallback),
            update: (folderId, sectionId, body, callback, errorCallback) => executePut(`/folders/${folderId}/sections/${sectionId}`, body, callback, errorCallback),
          },
        },
        inbox: {
          query: (query, callback, errorCallback) => executeQuery('inbox', query, callback, errorCallback),
          upload: (form, callback, errorCallback) => executePostForm('/inbox', form, callback, errorCallback),
          delete: (id, callback, errorCallback) => executeDelete(`/inbox/${id}`, callback, errorCallback),
        },
        tags: {
          load: (callback, errorCallback) => executeQuery('tags', null, callback, errorCallback),
          create: (newTag, callback, errorCallback) => executePost('/tags', { tag: newTag }, null, callback, errorCallback),
          delete: (id, callback, errorCallback) => executeDelete(`/tags/${id}`, callback, errorCallback),
        },
        users: {
          query: (callback, errorCallback) => executeQuery('users', null, callback, errorCallback),
          setRoles: (userId, body, callback, errorCallback) => executePost(`/users/${userId}`, null, body, callback, errorCallback),
          init: (userId, realName, callback, errorCallback) => executePost(`/users/${userId}/init`, null, { realName }, callback, errorCallback),
        },
      }}>
      {children}
    </Server.Provider>
  )
}

export default ServerProvider

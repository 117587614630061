/** @format */

import React, { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import ButtonBar from '../../components/button-bar/button-bar.component'
import DocumentViewer from '../../components/document-viewer/document-viewer.component'
import { Server } from '../../components/server/server.component'
import { auth } from '../../firebase/firebase.utils'
import { useHistory } from 'react-router-dom'
import DateInput from '../../components/date-input/date-input.component'
import StringInput from '../../components/string-input/string-input.component'
import FolderSectionSelect from '../../components/folder-section-select/folder-section-select.component'
import Tags from '../../components/tags/tags.component'

import styles from './edit.module.css'

const EditScreen = ({ match }) => {
  const [data, setData] = useState()
  const [folderItems, setFolderItems] = useState([])
  const [error, setError] = useState()

  const [date, setDate] = useState('')
  const [name, setName] = useState('')
  const [section, setSection] = useState('')
  const [folder, setFolder] = useState('')
  const [tags, setTags] = useState([])

  const { docs, folders } = useContext(Server)
  const history = useHistory()

  useEffect(() => {
    folders.load(setFolderItems, setError)
  }, [folders])

  useEffect(() => {
    docs.get(
      match.params.id,
      d => {
        auth.currentUser.getIdToken().then(token => {
          d.token = token
          setData(d)
        })
        setDate(d?.issueDate)
        setName(d?.title)
        setFolder(d?.folder)
        setSection(d?.section)
        setTags(d?.tags)
      },
      setError
    )
  }, [match.params.id, docs])

  const save = () => {
    docs.update(data.id, { issueDate: date, title: name, folder, section, tags }, history.goBack, console.error)
  }

  const fixPageCount = () => {
    docs.fixPageCount(data.id, setData, setError)
  }
  const ocr = () => {
    docs.ocr(data.id, setData, setError)
  }

  if (!data) {
    return null
  }
  return (
    <Container className={styles.edit}>
      {error && <Alert>{error}</Alert>}
      <Tags tagItems={tags} onChange={setTags} />
      <DateInput label='Datum' value={date} onChange={setDate} />
      <StringInput label='Titel' value={name} onChange={setName} />
      <FolderSectionSelect
        label='Ordner'
        folder={folder}
        section={section}
        onChange={(folder, section) => {
          setFolder(folder)
          setSection(section)
        }}
        folderItems={folderItems}
      />

      <div className={styles.value}>
        <span className={styles.label}>Seiten:</span> <span>{data.pageCount}</span>
      </div>
      <DocumentViewer doc={data} />
      <ButtonBar>
        <Button onClick={history.goBack}>Abbrechen</Button>
        <Button onClick={save}>Speichern</Button>
        <Button onClick={fixPageCount}>Fix</Button>
        <Button onClick={ocr}>OCR</Button>
      </ButtonBar>
    </Container>
  )
}

export default EditScreen

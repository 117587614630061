import React, { useContext, useState } from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import PasswordInput from '../../components/password-input/password-input.component';
import StringInput from '../../components/string-input/string-input.component';
import ButtonBar from '../button-bar/button-bar.component';
import { Server } from '../server/server.component';
import {auth} from '../../firebase/firebase.utils';
import { useHistory } from "react-router-dom";

import styles from './signup.module.css';

const Signup = () => {
    const { users } = useContext(Server);
    const [displayName, setDisplayName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState();
    const history = useHistory();

    const register = async () => {
        if (password !== passwordConfirm) {
            setError("Passwörter stimmen nicht überein!");
            return;
        }
        try {
            const {user} = await auth.createUserWithEmailAndPassword(username, passwordConfirm);
            users.init(user.uid, displayName, () => {}, setError);
            setError(null);
            history.replace("/");
        } catch(e) {
            setError(e.message);
        }
    }

    return (
        <div className={styles.signup}>
            <div className={styles.title}>Ich habe noch keinen Zugang</div>
            <div className={styles.subTitle}>Registriere dich mit Email und Passwort</div>
            <StringInput label="Anzeigename" value={displayName} onChange={setDisplayName} />
            <StringInput label="Email" value={username} onChange={setUsername} />
            <PasswordInput label="Passwort" value={password} onChange={setPassword} />
            <PasswordInput label="Passwort wiederholen" value={passwordConfirm} onChange={setPasswordConfirm} />
            <ButtonBar>
                <Button onClick={register}>Registrieren</Button>
            </ButtonBar>
            {
                error &&
                <Alert variant="danger">{error}</Alert>
            }
        </div>
    )
}

export default Signup

/** @format */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import styles from './tag.module.css'

const Tag = ({ children, onRemove }) => {
  const remove = () => {
    if (onRemove) {
      onRemove()
    }
  }
  const delIcon = onRemove ? (
    <span className={styles.deleteButton} onClick={remove}>
      <FontAwesomeIcon icon={faTrashAlt} />
    </span>
  ) : null
  return (
    <span className={styles.tag}>
      {children} {delIcon}
    </span>
  )
}

export default Tag

import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import FolderSettings from '../../components/folder-settings/folder-settings.component';
import Screen from '../../components/screen/screen.component';
import TagsSettings from '../../components/tags-settings/tags-settings.component';

const SettingsScreen = () => {
    return (
        <Screen>
            <h4>Einstellungen</h4>
            <Tabs>
                <Tab eventKey="Folders" title="Ordner und Abschnitte">
                    <FolderSettings />
                </Tab>
                <Tab eventKey="Tags" title="Tags">
                    <TagsSettings />
                </Tab>
            </Tabs>
        </Screen>
    )
}

export default SettingsScreen;

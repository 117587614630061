import { useContext, useEffect, useState } from 'react';
import Screen from '../../components/screen/screen.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { Server } from '../../components/server/server.component';
import User from '../../components/user/user.component';

const UsersScreen = () => {
    const [items, setItems] = useState([]);
    const [, setError] = useState();
    const { users } = useContext(Server);
    useEffect(() => {
        users.query(setItems, setError);
    }, [users])

    return (
        <Screen>
            <ScreenTitle label="Benutzer"/>
            {
                items && items.map(item => <User key={item.id} user={item} />)
            }
        </Screen>
    )
}

export default UsersScreen;

/** @format */

import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../server/server.component'

import styles from './document-viewer.module.css'

const DocumentViewer = ({ doc }) => {
  const [opened, setOpened] = useState(false)
  const [pageIdx, setPageIdx] = useState(0)
  const [showImage, setShowImage] = useState(true)

  if (!doc.pageCount) {
    return <div>Anzahl der Seiten unbekannt</div>
  }
  const pages = [...Array(doc.pageCount).keys()]
  return (
    <>
      <div className={styles.documentViewer}>
        {pages.map(p => (
          <img
            alt=''
            key={doc.id + '/' + p}
            onClick={() => {
              setPageIdx(p)
              setOpened(true)
            }}
            src={`${baseURL}/documents/${doc.id}/pages/${p}?token=${doc.token}`}
            style={{ height: 380 }}
          />
        ))}
      </div>
      <Modal show={opened} onHide={() => setOpened(false)} dialogClassName={styles.myModal}>
        <Modal.Header className={styles.myModalHeader}>
          <Modal.Title className={styles.myModalTitle}>
            {doc.issueDate} {doc.title}
          </Modal.Title>
          <span>
            <Button onClick={() => setShowImage(!showImage)}>{showImage ? 'Text' : 'Image'}</Button>
            <Button disabled={pageIdx === 0} onClick={() => setPageIdx(pageIdx - 1)}>
              {'<'}
            </Button>
            <Button disabled={pageIdx === doc.pageCount - 1} onClick={() => setPageIdx(pageIdx + 1)}>
              {'>'}
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body>{showImage ? <img alt={doc.title} src={`${baseURL}/documents/${doc.id}/pages/${pageIdx}?token=${doc.token}`} style={{ height: '80vh' }} /> : <pre>{doc.texts ? doc.texts[pageIdx] : null}</pre>}</Modal.Body>
      </Modal>
    </>
  )
}

export default DocumentViewer

/** @format */

import React, { useEffect, useState } from 'react'

import styles from './folder-section-select.module.css'

const FolderSectionSelect = ({ folder, section, onChange, folderItems }) => {
  const [sections, setSections] = useState([])

  useEffect(() => {
    if (folderItems && folder && section) {
      const f = folderItems.find(f => f.id === folder)
      if (f) {
        setSections(f.sections)
      } else {
        setSections(null)
      }
    }
  }, [folderItems, folder, section])

  const folderChanged = evt => {
    onChange(evt.target.value, section)
    const f = folderItems.find(f => f.id === evt.target.value)
    if (f) {
      setSections(f.sections)
    } else {
      setSections(null)
    }
  }
  const sectionChanged = evt => {
    onChange(folder, evt.target.value)
  }
  return (
    <div className={styles.folderSectionSelect}>
      <div className={styles.select}>
        <div className={styles.label}>{folder ? 'Ordner' : null}</div>
        <select value={folder} onChange={folderChanged}>
          <option>Ordner</option>
          {folderItems
            ? folderItems.map(f => (
                <option key={f.id} value={f.id}>
                  {f.number} {f.name}
                </option>
              ))
            : null}
        </select>
      </div>
      <div className={styles.select}>
        <div className={styles.label}>{section ? 'Abschnitt' : null}</div>
        <select value={section} onChange={sectionChanged}>
          <option>Abschnitt</option>
          {sections
            ? sections.map(f => (
                <option key={f.number} value={f.id}>
                  {f.number} {f.name}
                </option>
              ))
            : null}
        </select>
      </div>
    </div>
  )
}

export default FolderSectionSelect

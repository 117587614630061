import React, { createRef, useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FolderEditor from '../folder-editor/folder-editor.component';
import Screen from '../screen/screen.component';
import SectionEditor from '../section-editor/section-editor.component';
import { Server } from '../server/server.component';

// import styles from './folder-settings.module.css';

const FolderSettings = () => {
    const { folders } = useContext(Server);
    const [items, setItems] = useState([]);
    const [error, setError] = useState();
    const [selectedFolder, setSelectedFolder] = useState();
    const [selectedSection, setSelectedSection] = useState();
    const inputFolderRef = createRef();
    const inputSectionRef = createRef();

    useEffect(() => {
        folders.load(setItems, setError);
    }, [folders])

    const keyPressFolder = evt => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            folders.create(evt.target.value, () => folders.load(setItems, setError), setError);
            evt.target.value = '';
            inputFolderRef.current.focus();
        }
    };

    const reloadFolders = () => {
        folders.load(data => {
            setItems(data);
            data.forEach(item => {
                if (selectedFolder && item.id === selectedFolder.id) {
                    setSelectedFolder(item);
                }
            });
        }, setError)
    }

    const keyPressSections = evt => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            folders.sections.create(selectedFolder.id, evt.target.value, reloadFolders, setError);
            evt.target.value = '';
            inputSectionRef.current.focus();
        }
    };

    return (
        <Screen>
            <Row>
                <Col>
                    <h6>Ordner</h6>
                    <input ref={inputFolderRef} placeholder="zufügen" autoFocus onKeyPress={keyPressFolder} />
                    {
                        items ? items.map(folder => <FolderEditor
                            selected={selectedFolder && selectedFolder.id === folder.id}
                            onSelected={setSelectedFolder}
                            onChange={reloadFolders}
                            folder={folder} />) : null
                    }
                </Col>
                <Col>
                    <h6>Abschnitte</h6>
                    <input ref={inputSectionRef} disabled={!selectedFolder} placeholder="zufügen" autoFocus onKeyPress={keyPressSections} />
                    {
                        selectedFolder && selectedFolder.sections ? selectedFolder.sections.map(section => <SectionEditor
                            folderId={selectedFolder.id}
                            selected={selectedSection && selectedSection.id === section.id}
                            onSelected={setSelectedSection}
                            onChange={reloadFolders}
                            section={section} />) : null
                    }
                </Col>
            </Row>
        </Screen>
    )
}

export default FolderSettings

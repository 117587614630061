import React, { createRef, useContext, useEffect, useState } from 'react'
import Screen from '../screen/screen.component';
import { Server } from '../server/server.component';
import Tag from '../tag/tag.component';

const TagsSettings = () => {
    const [items, setItems] = useState([]);
    const [error, setError] = useState();
    const { tags } = useContext(Server);
    const inputRef = createRef()
    useEffect(() => {
        tags.load(setItems, setError);
    }, [tags])

    const deleteTag = tag => {
        tags.delete(tag.id, () => tags.load(setItems, setError), setError);
        inputRef.current.focus();
    };

    const keyPress = evt => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            tags.create(evt.target.value, () => tags.load(setItems, setError), setError);
            evt.target.value = '';
            inputRef.current.focus();
        }
    };

    return (
        <Screen>
            <input ref={inputRef} placeholder="zufügen" autoFocus onKeyPress={keyPress}/>
            <div>
                {
                    items ? items.map(t => <Tag onRemove={() => deleteTag(t)}>{t.name}</Tag>) : null
                }
            </div>
        </Screen>
    )
}

export default TagsSettings

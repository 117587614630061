/** @format */

import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { useHistory } from 'react-router-dom'
import Screen from '../../components/screen/screen.component'
import { SearchResult } from '../../components/search-result/search-result.component'
import { Server } from '../../components/server/server.component'
import StringInput from '../../components/string-input/string-input.component'

import styles from './home.module.css'

const HomeScreen = () => {
  const { setDocumentList, setSelectedIndex } = useContext(SearchResult)
  const { docs, folders } = useContext(Server)
  const [query, setQuery] = useState('')
  const [docIndex, setDocIndex] = useState([])
  const [error, setError] = useState()
  const history = useHistory()
  const [folderItems, setFolderItems] = useState([])
  const [roles, setRoles] = useState([])

  useEffect(() => {
    console.log('load folders')
    folders.load(data => {
      console.log('folders loaded', data)
      setFolderItems(data)
    }, setError)
  }, [folders])

  const doSearch = () => {
    if (folderItems?.length == 0) {
      folders.load(data => {
        console.log('folders loaded', data)
        setFolderItems(data)
      }, setError)
    }
    docs.query(
      query,
      data => {
        setDocIndex(data)
        setDocumentList(data)
      },
      setError
    )
  }

  const keyPress = ({ charCode }) => {
    if (charCode === 13) {
      doSearch()
    }
  }

  const openDetails = (doc, idx) => {
    setSelectedIndex(idx)
    history.push(`/documents/${doc.id}`)
  }

  const decodeFolder = (folderId, section) => {
    console.log('decode folder', folderId, section, folderItems)
    var folder = folderItems.find(f => f.id === folderId)
    console.log('folder', folder)
    if (!folder) {
      return '99 Unbekannt/99 Unbekannt'
    }
    var s = folder.sections.find(s => s.id === section)
    console.log('s', s)
    return folder.number + ' ' + folder.name + '/' + s.number + ' ' + s.name
  }

  const browse = () => {
    history.push(`/browser`)
  }

  if (!roles) {
    return <Container>Ihnen wurde keine Rolle zugewiesen. Wenden Sie sich an den Administrator.</Container>
  }

  return (
    <Screen>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15, marginBottom: 15 }}>
        <StringInput label='Suchtext' value={query} onKeyPress={keyPress} onChange={setQuery} />
        <Button onClick={browse}>Blättern</Button>
      </div>
      {docIndex.map((doc, idx) => (
        <div key={doc.id} className={styles.dataRow} onClick={() => openDetails(doc, idx)}>
          <span className={styles.issueDate}>{doc.issueDate}</span>
          <span className={styles.docTitle}>{doc.title}</span>
          <span>{decodeFolder(doc.folder, doc.section)}</span>
        </div>
      ))}
    </Screen>
  )
}

export default HomeScreen

import React, { createContext, useState } from 'react'

export const SearchResult = createContext();

const SearchResultProvider = ({children}) => {
    const [documentList, setDocumentList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedFolderId, setSelectedFolderId] = useState();
    const [selectedSectionId, setSelectedSectionId] = useState();
    const [query, setQuery] = useState();
    return (
        <SearchResult.Provider value={{
            documentList: documentList,
            setDocumentList: setDocumentList,
            selectedIndex: selectedIndex,
            setSelectedIndex, setSelectedIndex,
            selectedFolderId: selectedFolderId,
            setSelectedFolderId: setSelectedFolderId,
            selectedSectionId: selectedSectionId,
            setSelectedSectionId: setSelectedSectionId,
            query: query,
            setQuery: setQuery,
        }}>
            {children}
        </SearchResult.Provider>
    )
}

export default SearchResultProvider;

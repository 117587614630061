import React from 'react';
import Container from 'react-bootstrap/Container';
import CheckAccess from '../../components/check-access/check-access.component';

import styles from './screen.module.css';

const Screen = (props) => {
    return <CheckAccess>
        <Container className={styles.screen} {...props}>
            {props.children}
        </Container>
    </CheckAccess>;
}

export default Screen;

/** @format */

import React, { createRef, useContext, useEffect, useState } from 'react'
import { Server } from '../server/server.component'
import Tag from '../tag/tag.component'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import styles from './tags.module.css'

const Tags = ({ tagItems, onChange }) => {
  const inputRef = createRef()
  const { tags } = useContext(Server)
  const [items, setItems] = useState([])
  const [error, setError] = useState()
  const [filtered, setFiltered] = useState([])
  const [value, setValue] = useState('')

  const findTag = tagId => items.find(t => t?.id === tagId)

  useEffect(() => {
    tags.load(res => {
      setItems(res)
      setFiltered(items.filter(t => t?.name?.toLowerCase()?.includes(value.toLowerCase()) && !tagItems?.find(x => t?.id === x)))
    }, setError)
    updateValue('')
  }, [tags])

  useEffect(() => {
    setFiltered(items.filter(t => t?.name?.toLowerCase()?.includes(value.toLowerCase()) && !tagItems?.find(x => t?.id === x)))
    updateValue('')
  }, [tagItems, items])

  const deleteTag = tagId => {
    onChange(tagItems.filter(id => id !== tagId))
  }

  const exactMatch = name => filtered.find(t => t?.name === name)

  const keyPressed = evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      const name = evt.target.value
      if (filtered.length === 1) {
        addTag(filtered[0])
      } else if (!exactMatch(name)) {
        tags.create(
          name,
          data => {
            tags.load(res => {
              setItems(res)
              addTag(data)
            }, setError)
          },
          setError
        )
      } else {
        addTag(filtered.find(t => t.name === name))
      }
      evt.target.value = ''
      inputRef.current.blur()
      inputRef.current.focus()
    }
  }

  const addTag = item => {
    const newItems = [...(tagItems || []), item.id]
    onChange(newItems)
  }

  const updateValue = txt => {
    setValue(txt)
    setFiltered(items.filter(t => t.name.toLowerCase().includes(txt.toLowerCase()) && !tagItems?.find(x => t.id === x)))
  }

  const popover = filtered ? (
    <Popover>
      <Popover.Content>
        {filtered.map(item => (
          <div key={item.id} onClick={() => addTag(item)}>
            {item.name}
          </div>
        ))}
      </Popover.Content>
    </Popover>
  ) : null

  return (
    <div className={styles.tags}>
      {tagItems &&
        tagItems.map(t => (
          <Tag key={t.id} onRemove={() => deleteTag(t)}>
            {findTag(t)?.name}
          </Tag>
        ))}
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement='auto' overlay={popover} trigger='focus'>
        <input ref={inputRef} placeholder='Tag' onKeyPress={keyPressed} value={value} onChange={({ target }) => updateValue(target.value)} />
      </OverlayTrigger>
    </div>
  )
}

export default Tags

import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Server } from '../../components/server/server.component';
import { useHistory } from "react-router-dom";
import { SearchResult } from '../../components/search-result/search-result.component';

import styles from './browser.module.css';
import Screen from '../../components/screen/screen.component';

const BrowserScreen = () => {
    const { setDocumentList, setSelectedIndex, selectedFolderId, setSelectedFolderId, selectedSectionId, setSelectedSectionId, setQuery } = useContext(SearchResult);
    const { docs, folders } = useContext(Server);
    const [folderItems, setFolderItems] = useState([]);
    const [error, setError] = useState();
    const [selectedFolder, setSelectedFolder] = useState();
    const [selectedSection, setSelectedSection] = useState();
    const [documents, setDocuments] = useState([]);
    const history = useHistory();

    useEffect(() => {
        folders.load(setFolderItems, setError);
    }, [folders]);

    useEffect(() => {
        if (folderItems && selectedFolderId) {
            const x = folderItems.find(f => f.id === selectedFolderId);
            if (x) {
                setSelectedFolder(x);
                if (selectedSectionId && x.sections) {
                    const y = x.sections.find(s => s.id === selectedSectionId);
                    if (y) {
                        selectSection(y);
                    }
                }
            }
        }
    }, [folderItems, selectedFolderId, selectedSectionId]);

    const selectFolder = folder => {
        setDocuments(null);
        setSelectedFolder(folder);
        setSelectedFolderId(folder.id);
        setQuery();
    };

    const selectSection = section => {
        setSelectedSection(section);
        setSelectedSectionId(section.id);
        if (selectedFolder) {
            docs.select(selectedFolder.id, section.id, data => {
                setDocuments(data);
                setDocumentList(data);
            }, setError);
        }
    };

    const openDetails = (doc, idx) => {
        setSelectedIndex(idx);
        history.push(`/documents/${doc.id}`)
    };

    return (
        <Screen>
            <div className={styles.browser}>
                <div className={styles.column1}>
                    <h1>Ordner auswählen</h1>
                    {folderItems &&
                        folderItems.map(f => <div key={f.id} className={f === selectedFolder ? styles.selectedFolder : null} onClick={() => selectFolder(f)}>{f.number} {f.name}</div>)}
                </div>
                <div className={styles.column2}>
                    <h1>Abschnitt auswählen</h1>
                    {selectedFolder && selectedFolder.sections &&
                        selectedFolder.sections.map(s => <div key={s.id} className={s === selectedSection ? styles.selectedSection : null} onClick={() => selectSection(s)}>{s.number} {s.name}</div>)}
                </div>
                <div className={styles.column3}>
                    <h1>Dokument auswählen</h1>
                    {documents && documents.map((doc, idx) => <div key={doc.id} className={styles.document} onClick={() => openDetails(doc, idx)}><span className={styles.issueDate}>{doc.issueDate}</span> <span className={styles.title}>{doc.title}</span></div>)}
                </div>
            </div>
        </Screen>
    )
}

export default BrowserScreen;
/** @format */

import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useDrop } from 'react-dnd'
import { Server } from '../server/server.component'
import EditorPreview from '../editor-preview/editor-preview.component'
import DateInput from '../date-input/date-input.component'
import StringInput from '../string-input/string-input.component'
import FolderSectionSelect from '../folder-section-select/folder-section-select.component'
import ButtonBar from '../button-bar/button-bar.component'
import Tags from '../tags/tags.component'
import { auth } from '../../firebase/firebase.utils'

import styles from './editor.module.css'

const Editor = ({ dropped }) => {
  const { editor, folders } = useContext(Server)
  const [file, setFile] = useState([])
  const [date, setDate] = useState('')
  const [name, setName] = useState('')
  const [folder, setFolder] = useState('')
  const [section, setSection] = useState('')
  const [folderItems, setFolderItems] = useState([])
  const [error, setError] = useState()
  const [tags, setTags] = useState([])
  const [original, setOriginal] = useState(false)

  useEffect(() => {
    folders.load(setFolderItems, setError)
  }, [folders])

  useEffect(() => {
    query()
  }, [editor])

  const query = () => {
    editor.query(
      null,
      f => {
        doSetFile(f)
      },
      setError
    )
  }

  const doSetFile = file => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken().then(token => {
        file.token = token
        setFile(file)
      })
    }
  }

  const cleanup = () => {
    console.log('cleanup')
    setFile(null)
    query()
    setDate('')
    setName('')
    setTags([])
  }

  const merge = () => {
    editor.merge(
      {
        original,
        date,
        name,
        folder,
        section,
        tags,
      },
      cleanup,
      console.log
    )
  }

  const select = item => {
    if (item.pages.find(page => page.status !== 'CONVERTED')) {
      return undefined
    }
    editor.select(
      item.id,
      f => {
        doSetFile(f)
        dropped(item.id)
      },
      console.log
    )
    return undefined
  }

  const [{ isOver }, drop] = useDrop({
    accept: 'InboxItem',
    drop: item => select(item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  const style = isOver ? { backgroundColor: '#ddd' } : {}

  return (
    <div ref={drop} className={styles.editor}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Editor</span>
        <Button onClick={query}>Aktualisieren</Button>
      </div>

      <div className={styles.editorPreview} style={style}>
        <EditorPreview doc={file} />
      </div>
      {file && file.type === 'application/pdf' && (
        <div>
          <input type='checkbox' id='PDF' checked={original} onChange={({ target }) => setOriginal(target.checked)} />
          <label for='PDF'>Im Original importieren</label>
        </div>
      )}
      <DateInput label='Datum' value={date} onChange={setDate} />
      <StringInput label='Name' value={name} onChange={setName} />
      <FolderSectionSelect
        label='Ordner'
        folder={folder}
        section={section}
        onChange={(folder, section) => {
          setFolder(folder)
          setSection(section)
        }}
        folderItems={folderItems}
      />
      <Tags tagItems={tags} onChange={setTags} />
      <ButtonBar>
        <Button onClick={merge}>Merge</Button>
      </ButtonBar>
    </div>
  )
}

export default Editor

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { baseURL } from '../server/server.component';

import styles from './editor-preview.module.css';

const EditorPreview = ({ isDragging, doc }) => {
    const [opened, setOpened] = useState(false);
    const [pageIdx, setPageIdx] = useState(0);

    if (!doc) {
        return null;
    }

    return <>
        {
            doc && doc.pages && doc.pages.map((page, idx) =>
                <div key={idx}>
                    <div style={{ marginRight: 10 }}>
                        <img onClick={() => {
                            setPageIdx(idx);
                            setOpened(true);
                        }} alt={doc.name} src={`${baseURL}/editor/${doc.id}?page=${idx}&token=${doc.token}`} style={{ height: 350 }} />
                    </div>
                </div>)
        }
        <Modal show={opened} onHide={() => setOpened(false)}
            dialogClassName={styles.myModal}>
            <Modal.Header className={styles.myModalHeader}>
                <Modal.Title className={styles.myModalTitle}>{doc.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <img alt={doc.name} src={`${baseURL}/editor/${doc.id}?page=${pageIdx}&token=${doc.token}`} style={{ height: "80vh" }} />
                </div>
            </Modal.Body>
        </Modal>
    </>;
}

export default EditorPreview;
